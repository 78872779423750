import axiosInstance from '../axios-instance';

export function getScanners(paginationParams = { PageNumber: 1, PageSize: 10 }, eventId = '') {
  return axiosInstance.get('cms/scanners', { params: { ...paginationParams, eventId } });
}

export function addScanner(payload) {
  return axiosInstance.post('cms/scanners', payload);
}

export function getScanner(id) {
  return axiosInstance.get(`cms/scanners/${id}`);
}

export function updateScanner(payload) {
  return axiosInstance.put(`cms/scanners/${payload.id}`, payload);
}

export function daleteScanner(id) {
  return axiosInstance.delete(`cms/scanners/${id}`);
}
