<template>
  <div
    aria-atomic="true"
    aria-live="assertive"
    class="toast show toast-container position-fixed bottom-4 end-4 p-3"
    role="alert"
    style="background-color: #ffecf0; z-index: 1000000000000"
  >
    <div class="toast-header bg-danger-soft-4 text-white">
      <strong class="me-auto">Ops. error occurred</strong>
    </div>
    <div v-if="toastText.trim()" class="toast-body bold text-black">
      {{ toastText }}
    </div>
    <div v-else>Something Wrong Occurred, please Try Again Later</div>
  </div>
</template>

<script>
  import { Toast } from 'bootstrap/dist/js/bootstrap.esm';

  export default {
    name: 'Toast-Component',
    mounted() {
      Array.from(document.querySelectorAll('.toast')).forEach((toastNode) => new Toast(toastNode));
      setTimeout(() => {
        this.$store.commit('updateToastStatus', false);
        this.$store.commit('updateToastText', '');
      }, 3000);
    },
    computed: {
      toastText() {
        return this.$store.state.toastText ?? '';
      },
    },
  };
</script>
