import { addTicketTypes, daleteTicketType, getTicketsType, updateTicketType } from '../../api/ticketTypes';

export default {
  namespaced: true,
  state: {
    ticketTypeList: { data: [], totalCount: 0 },
    ticketTypeDetails: {},
    editTicketTypeDetails: null,
    progress: {
      ticketTypeList: { loading: false, loadingFailed: false },
      addTicketType: { loading: false, loadingFailed: false },
      deleteTicketType: { loading: false, loadingFailed: false },
      updateTicketType: { loading: false, loadingFailed: false },
    },
    modals: { addTicketType: false, deleteTicketType: false, editTicketType: false },
    ticketTypeId: '',
    eventId: '',
  },

  actions: {
    loadTicketType({ commit, state }, payload) {
      if (state.progress.ticketTypeList.loading) return;
      commit('SET_TICKETS_TYPE_LOADING_INPROGRESS', true);
      commit('SET_TICKETS_TYPE_LOADING_FAILED', false);
      getTicketsType(payload)
        .then((tResp) => {
          commit('SET_TICKETS_TYPE_LOADING_INPROGRESS', false);
          commit('SET_TICKETS_TYPE_LOADING_FAILED', false);
          commit('APPLY_TICKET_TYPE', tResp.data);
        })
        .catch(() => {
          commit('SET_TICKETS_TYPE_LOADING_INPROGRESS', false);
          commit('SET_TICKETS_TYPE_LOADING_FAILED', true);
        });
    },

    addTicket({ commit, state, dispatch }, payload) {
      if (state.progress.ticketTypeList.loading) return;
      if (state.progress.addTicketType.loading) return;
      commit('SET_ADD_TYPE_LOADING_INPROGRESS', true);
      commit('SET_ADD_TYPE_LOADING_FAILED', false);
      addTicketTypes(payload)
        .then(() => {
          commit('TOGGLE_ADD_TYPE_MODAL');
          commit('SET_ADD_TYPE_LOADING_INPROGRESS', false);
          commit('SET_ADD_TYPE_LOADING_FAILED', false);
          dispatch('EventsStore/getEvent', { eventId: state.eventId }, { root: true });
        })
        .catch(() => {
          commit('SET_ADD_TYPE_LOADING_INPROGRESS', false);
          commit('SET_ADD_TYPE_LOADING_FAILED', true);
        });
    },

    deleteTicket({ commit, state, dispatch }, payload) {
      if (state.progress.ticketTypeList.loading) return;
      if (state.progress.deleteTicketType.loading) return;
      commit('SET_DELETE_TYPE_LOADING_INPROGRESS', true);
      commit('SET_DELETE_TYPE_LOADING_FAILED', false);
      daleteTicketType(payload)
        .then(() => {
          commit('SET_DELETE_TYPE_LOADING_INPROGRESS', false);
          commit('SET_DELETE_TYPE_LOADING_FAILED', false);
          commit('TOGGLE_DELETE_TYPE_MODAL');
          dispatch('EventsStore/getEvent', { eventId: state.eventId }, { root: true });
        })
        .catch(() => {
          commit('SET_DELETE_TYPE_LOADING_INPROGRESS', false);
          commit('SET_DELETE_TYPE_LOADING_FAILED', true);
        });
    },

    updateTicket({ commit, state, dispatch }, payload) {
      if (state.progress.ticketTypeList.loading) return;
      if (state.progress.updateTicketType.loading) return;
      commit('SET_UPDATE_TYPE_LOADING_INPROGRESS', true);
      commit('SET_UPDATE_TYPE_LOADING_FAILED', false);
      updateTicketType(payload)
        .then(() => {
          commit('SET_UPDATE_TYPE_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_TYPE_LOADING_FAILED', false);
          commit('TOGGLE_EDIT_TYPE_MODAL');
          dispatch('EventsStore/getEvent', { eventId: state.eventId }, { root: true });
        })
        .catch(() => {
          commit('SET_UPDATE_TYPE_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_TYPE_LOADING_FAILED', true);
        });
    },
  },

  mutations: {
    SET_TICKETS_TYPE_LOADING_INPROGRESS: (state, value) => (state.progress.ticketTypeList.loading = value),
    SET_TICKETS_TYPE_LOADING_FAILED: (state, value) => (state.progress.ticketTypeList.loadingFailed = value),

    SET_ADD_TYPE_LOADING_INPROGRESS: (state, value) => (state.progress.addTicketType.loading = value),
    SET_ADD_TYPE_LOADING_FAILED: (state, value) => (state.progress.addTicketType.loadingFailed = value),

    SET_DELETE_TYPE_LOADING_INPROGRESS: (state, value) => (state.progress.deleteTicketType.loading = value),
    SET_DELETE_TYPE_LOADING_FAILED: (state, value) => (state.progress.deleteTicketType.loadingFailed = value),

    SET_UPDATE_TYPE_LOADING_INPROGRESS: (state, value) => (state.progress.updateTicketType.loading = value),
    SET_UPDATE_TYPE_LOADING_FAILED: (state, value) => (state.progress.updateTicketType.loadingFailed = value),

    APPLY_TICKET_TYPE: (state, payload) => (state.ticketTypeList = payload),
    SET_TICKET_TYPE_ID: (state, ticketTypeId) => (state.ticketTypeId = ticketTypeId),
    SET_TICKET_TYPE_DETAILS: (state, payload) => (state.editTicketTypeDetails = payload),
    SET_EVENT_ID: (state, eventId) => (state.eventId = eventId),

    TOGGLE_ADD_TYPE_MODAL: (state) => (state.modals.addTicketType = !state.modals.addTicketType),
    TOGGLE_EDIT_TYPE_MODAL: (state) => (state.modals.editTicketType = !state.modals.editTicketType),
    TOGGLE_DELETE_TYPE_MODAL: (state) => (state.modals.deleteTicketType = !state.modals.deleteTicketType),
  },
};
