import { addOrder, getOrder, getOrders, updateOrderStatus, exportOrders } from '@/api/orders';

export default {
  namespaced: true,
  state: {
    ordersList: { data: [], pageNumber: 0, pageSize: 0, totalCount: 0 },
    filterParams: {
      StartDate: null,
      EndDate: null,
      Status: null,
      TrackingNumber: null,
      EventId: null,
      CustomerId: null,
      PageNumber: 1,
      PageSize: 10,
    },
    isFilterApplied: false,
    progress: {
      ordersList: { loading: false, loadingFailed: false },
      getOrder: { loading: false, loadingFailed: false },
      addOrder: { loading: false, loadingFailed: false },
      updateOrderStatus: { loading: false, loadingFailed: false },
      filterOrder: { loading: false, loadingFailed: false },
      exportOrders: { loading: false, loadingFailed: false },
    },
    modals: { addOrder: false, updateOrderStatus: false, filterOrder: false, bulkUpdateStatus: false },
    orderDetails: {},
    orderId: '',
    orderStatus: 0,
    ordersFile: null,
  },
  actions: {
    loadOrders({ state, commit }) {
      if (state.progress.ordersList.loading) return;
      commit('SET_ORDER_LIST_LOADING_INPROGRESS', true);
      commit('SET_ORDER_LIST_LOADING_FAILED', false);
      getOrders(state.filterParams)
        .then((tResp) => {
          commit('SET_ORDER_LIST_LOADING_INPROGRESS', false);
          commit('SET_ORDER_LIST_LOADING_FAILED', false);
          commit('APPLY_ORDERS_LIST', tResp.data);
          state.modals.filterOrder = false;
        })
        .catch(() => {
          commit('SET_ORDER_LIST_LOADING_INPROGRESS', false);
          commit('SET_ORDER_LIST_LOADING_FAILED', true);
        });
    },

    loadExportedOrders({ state, commit }) {
      commit('SET_EXPORT_ORDERS_LOADING_INPROGRESS', true);
      commit('SET_EXPORT_ORDERS_LOADING_FAILED', false);
      exportOrders(state.filterParams)
        .then((tResp) => {
          commit('SET_EXPORT_ORDERS_LOADING_INPROGRESS', false);
          commit('SET_EXPORT_ORDERS_LOADING_FAILED', false);
          commit('SET_ORDER_CSV_DATA', tResp.data);
          commit('DOWNLOAD_FILE', tResp.data);
        })
        .catch(() => {
          commit('SET_EXPORT_ORDERS_LOADING_INPROGRESS', false);
          commit('SET_EXPORT_ORDERS_LOADING_FAILED', true);
        });
    },

    loadOrderById({ state, commit }) {
      if (state.progress.getOrder.loading) return;
      commit('SET_GET_ORDER_LOADING_INPROGRESS', true);
      commit('SET_GET_ORDER_LOADING_FAILED', false);
      getOrder(state.orderId)
        .then((tResp) => {
          commit('SET_GET_ORDER_LOADING_INPROGRESS', false);
          commit('SET_GET_ORDER_LOADING_FAILED', false);
          commit('APPLY_GET_ORDER', tResp.data);
          console.log(tResp.data);
        })
        .catch(() => {
          commit('SET_GET_ORDER_LOADING_INPROGRESS', false);
          commit('SET_GET_ORDER_LOADING_FAILED', true);
        });
    },

    addNewOrder({ state, commit, dispatch }, payload) {
      if (state.progress.ordersList.loading) return;
      if (state.progress.addOrder.loading) return;
      commit('SET_ADD_ORDER_LOADING_INPROGRESS', true);
      commit('SET_ADD_ORDER_LOADING_FAILED', false);
      addOrder(payload)
        .then((tResp) => {
          commit('SET_ADD_ORDER_LOADING_INPROGRESS', false);
          commit('SET_ADD_ORDER_LOADING_FAILED', false);
          commit('TOGGLE_ADD_ORDER_MODAL');
          commit('APPLY_ORDERS_LIST', tResp.data);
          dispatch('loadOrders');
        })
        .catch(() => {
          commit('SET_ADD_ORDER_LOADING_INPROGRESS', false);
          commit('SET_ADD_ORDER_LOADING_FAILED', true);
        });
    },

    updateStatus({ state, commit, dispatch }, payload) {
      if (state.progress.ordersList.loading) return;
      if (state.progress.updateOrderStatus.loading) return;
      commit('SET_UPDATE_ORDER_LOADING_INPROGRESS', true);
      commit('SET_UPDATE_ORDER_LOADING_FAILED', false);
      updateOrderStatus(state.orderId, payload)
        .then(() => {
          commit('SET_UPDATE_ORDER_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_ORDER_LOADING_FAILED', false);
          commit('TOGGLE_UPDATE_ORDER_STATUS_MODAL');
          dispatch('loadOrders');
        })
        .catch((error) => {
          console.log(error);

          commit('SET_UPDATE_ORDER_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_ORDER_LOADING_FAILED', true);
        });
    },
  },
  mutations: {
    SET_ORDER_LIST_LOADING_INPROGRESS: (state, value) => (state.progress.ordersList.loading = value),
    SET_ORDER_LIST_LOADING_FAILED: (state, value) => (state.progress.ordersList.loadingFailed = value),

    SET_EXPORT_ORDERS_LOADING_INPROGRESS: (state, value) => (state.progress.exportOrders.loading = value),
    SET_EXPORT_ORDERS_LOADING_FAILED: (state, value) => (state.progress.exportOrders.loadingFailed = value),

    SET_GET_ORDER_LOADING_INPROGRESS: (state, value) => (state.progress.getOrder.loading = value),
    SET_GET_ORDER_LOADING_FAILED: (state, value) => (state.progress.getOrder.loadingFailed = value),

    SET_ADD_ORDER_LOADING_INPROGRESS: (state, value) => (state.progress.addOrder.loading = value),
    SET_ADD_ORDER_LOADING_FAILED: (state, value) => (state.progress.addOrder.loadingFailed = value),

    SET_UPDATE_ORDER_LOADING_INPROGRESS: (state, value) => (state.progress.updateOrderStatus.loading = value),
    SET_UPDATE_ORDER_LOADING_FAILED: (state, value) => (state.progress.updateOrderStatus.loadingFailed = value),

    APPLY_ORDERS_LIST: (state, payload) => (state.ordersList = payload),
    APPLY_GET_ORDER: (state, payload) => (state.orderDetails = payload),
    APPLY_FILTER_ORDER: (state, payload) => {
      state.filterParams = {
        PageNumber: payload.PageNumber ?? 1,
        PageSize: payload.PageSize ?? 10,
        EventId: payload.eventId ?? null,
        CustomerId: payload.customerId ?? null,
        Status: payload.status ?? null,
        TrackingNumber: payload.TrackingNumber ?? null,
        StartDate: payload.startDate ?? null,
        EndDate: payload.endDate ?? null,
      };
    },
    APPLY_FILTER_TRIGGER: (state, payload) => (state.isFilterApplied = payload),
    SET_ORDER_ID: (state, payload) => (state.orderId = payload),
    SET_ORDER_STATUS: (state, payload) => (state.orderStatus = payload),
    SET_ORDER_CSV_DATA: (state, payload) => (state.ordersFile = payload),
    DOWNLOAD_FILE: (state, payload) => {
      const blob = new Blob([payload], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Orders_${new Date()}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    TOGGLE_ADD_ORDER_MODAL: (state) => (state.modals.addOrder = !state.modals.addOrder),
    TOGGLE_UPDATE_ORDER_STATUS_MODAL: (state) => (state.modals.updateOrderStatus = !state.modals.updateOrderStatus),
    TOGGLE_FILTER_ORDER_MODAL: (state) => (state.modals.filterOrder = !state.modals.filterOrder),
    TOGGLE_BULK_UPDATE_STATUS_MODAL: (state) => (state.modals.bulkUpdateStatus = !state.modals.bulkUpdateStatus),
  },
};
