import './assets/js/nav-pills.js';
import './assets/scss/argon-dashboard.scss';
import './assets/css/nucleo-icons.css';
import './assets/css/nucleo-svg.css';
import './axios-instance';

export default {
  install() {
  },
};
