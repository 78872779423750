import router from '../../router/index';
import { adminLogin, getCurrentAdmin, updateCurrentAdmin, resetPasswordCurrentAdmin } from '@/api/admin';

export default {
  namespaced: true,
  state: {
    admin: {},
    adminDetails: {},
    loading: false,
    loginFailed: false,
  },

  actions: {
    login({ commit, state, dispatch }, payload) {
      if (state.loading) return;
      commit('SET_LOADING_IN_PROGRESS', true);
      commit('SET_LOADING_FAILED', false);
      return adminLogin(payload)
        .then((data) => {
          commit('SET_ADMIN_AUTH_DATA', data.data);
          dispatch('getCurrentAdmin');
        })
        .catch(() => {
          commit('SET_LOADING_IN_PROGRESS', false);
          commit('SET_LOADING_FAILED', true);
        });
    },

    getCurrentAdmin({ commit }) {
      return getCurrentAdmin()
        .then((tResp) => {
          commit('APPLY_CURRENT_ADMIN_DETAILS', tResp.data.admin);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    updateCurrentAdmin({ dispatch }, payload) {
      return updateCurrentAdmin(payload)
        .then(() => {
          dispatch('AdminsStore/loadAdminList', { pageNumber: 1, pageSize: 10 }, { root: true });
        })
        .catch((err) => console.log(err));
    },

    // eslint-disable-next-line no-empty-pattern
    resetPasswordCurrentAdmin({}, payload) {
      return resetPasswordCurrentAdmin(payload)
        .then((data) => console.log(data))
        .catch((err) => console.log(err));
    },
  },

  mutations: {
    SET_LOADING_IN_PROGRESS: (state, value) => (state.loading = value),
    SET_LOADING_FAILED: (state, value) => (state.loginFailed = value),
    SET_ADMIN_AUTH_DATA: (state, payload) => {
      state.loading = false;
      state.admin = payload;
      localStorage.setItem('admin', JSON.stringify(state.admin));
      router.push({ name: 'Dashboard' });
    },
    APPLY_CURRENT_ADMIN_DETAILS: (state, payload) => (state.adminDetails = payload),
  },
};
