import axiosInstance from '../axios-instance';

export function getAdmins(paginationParams = { PageNumber: 1, PageSize: 10 }) {
  return axiosInstance.get('cms/admins', { params: { ...paginationParams } });
}

export function addAdmin(payload) {
  return axiosInstance.post('cms/admins', payload);
}

export function getAdmin(id) {
  return axiosInstance.get(`cms/admins/${id}`);
}

export function updateAdmin(payload) {
  return axiosInstance.put(`cms/admins/${payload.id}`, payload);
}

export function deleteAdmin(id) {
  return axiosInstance.delete(`cms/admins/${id}`);
}

export function resetPasswordForAdmin(payload) {
  return axiosInstance.put(`cms/admins/${payload.id}/reset-password`, payload);
}
