<template>
  <nav
    id="navbarBlur"
    :class="this.$store.state.isRTL ? 'top-0 position-sticky z-index-sticky' : ''"
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    data-scroll="true"
    v-bind="$attrs"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs :currentPage="currentRouteName" textWhite="text-white" />
    </div>
    <ul class="navbar-nav justify-content-end">
      <li class="nav-item d-xl-none ps-3 d-flex align-items-center">
        <a id="iconNavbarSidenav" class="p-0 nav-link text-white" href="#" @click="toggleSidebar">
          <div class="sidenav-toggler-inner">
            <i class="sidenav-toggler-line bg-white"></i>
            <i class="sidenav-toggler-line bg-white"></i>
            <i class="sidenav-toggler-line bg-white"></i>
          </div>
        </a>
      </li>
    </ul>
  </nav>
</template>
<script>
  import Breadcrumbs from '../Breadcrumbs.vue';
  import { mapMutations, mapActions } from 'vuex';

  export default {
    name: 'navbar-component',
    data() {
      return {
        showMenu: false,
      };
    },
    components: {
      Breadcrumbs,
    },
    computed: {
      currentRouteName() {
        return this.$route.name;
      },
    },
    methods: {
      ...mapMutations(['navbarMinimize']),
      ...mapActions(['toggleSidebarColor']),

      toggleSidebar() {
        this.navbarMinimize();
      },
    },
  };
</script>
