import axiosInstance from '../axios-instance';

export function adminLogin(payload) {
  return axiosInstance.post('auth/admin/login', payload);
}

export function adminRefreshToken() {
  return axiosInstance.post(
    'auth/admin/refresh',
    {},
    {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem('admin'))?.refreshToken ?? ''}`,
      },
    }
  );
}

export function getCurrentAdmin() {
  return axiosInstance.get('admin/current');
}

export function updateCurrentAdmin(payload) {
  return axiosInstance.put('admin/current', payload);
}

export function resetPasswordCurrentAdmin(payload) {
  return axiosInstance.put('admin/current/reset-password', payload);
}
