import { sendNotification } from '@/api/notification';

export default {
  namespaced: true,
  state: {
    progress: {
      sendNotification: { loading: false, loadingFailed: false },
    },
    modals: { sendNotification: false },
  },

  actions: {
    broadcastNotification({ commit, state }, payload) {
      if (state.progress.sendNotification.loading) return;
      commit('SET_SEND_NOTIFICATION_LOADING_PROGRESS', true);
      commit('SET_SEND_NOTIFICATION_LOADING_FAILED', false);
      sendNotification(payload)
        .then((tResp) => {
          if (tResp.status >= 400) return;
          commit('SET_SEND_NOTIFICATION_LOADING_PROGRESS', false);
          commit('SET_SEND_NOTIFICATION_LOADING_FAILED', false);
          commit('TOGGLE_SEND_NOTIFICATION_MODAL');
        })
        .catch(() => {
          commit('SET_SEND_NOTIFICATION_LOADING_PROGRESS', false);
          commit('SET_SEND_NOTIFICATION_LOADING_FAILED', true);
        });
    },
  },

  mutations: {
    SET_SEND_NOTIFICATION_LOADING_PROGRESS: (state, value) => (state.progress.sendNotification.loading = value),
    SET_SEND_NOTIFICATION_LOADING_FAILED: (state, value) => (state.progress.sendNotification.loadingFailed = value),
    TOGGLE_SEND_NOTIFICATION_MODAL: (state) => (state.modals.sendNotification = !state.modals.sendNotification),
  },
};
