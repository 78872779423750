import axiosInstance from '../axios-instance';

export function getCustomers(filterParams) {
  return axiosInstance.get('cms/customers', { params: { ...filterParams } });
}

export function addCustomer(payload) {
  return axiosInstance.post('cms/customers', payload);
}

export function getCustomer(id) {
  return axiosInstance.get(`cms/customers/${id}`);
}

export function updateCustomer(payload) {
  return axiosInstance.put(`cms/customers/${payload.id}`, payload);
}

export function deleteCustomer(id) {
  return axiosInstance.delete(`cms/customers/${id}`);
}
