<template>
  <div
    v-show="this.$store.state.layout === 'default'"
    :class="`${this.$store.state.darkMode ? 'bg-transparent' : 'bg-success'}`"
    class="min-height-300 position-absolute w-100"
  ></div>
  <aside
    id="sidenav-main"
    :class="`${this.$store.state.isRTL ? 'me-3 rotate-caret fixed-end' : 'fixed-start ms-3'} 
    ${this.$store.state.layout === 'landing' ? 'bg-transparent shadow-none' : ' '} ${this.$store.state.sidebarType}`"
    class="my-3 overflow-auto border-0 sidenav navbar navbar-vertical navbar-expand-xs border-radius-xl"
  >
    <div class="sidenav-header">
      <i
        id="iconSidenav"
        aria-hidden="true"
        class="top-0 p-3 cursor-pointer fas fa-times text-secondary opacity-5 position-absolute end-0 d-none d-xl-none"
      ></i>
      <router-link class="m-0 navbar-brand" to="/dashboard">
        <img
          :src="
            this.$store.state.darkMode || this.$store.state.sidebarType === 'bg-default' ? tickityLogo : tickityLogo
          "
          alt="main_logo"
          class="navbar-brand-img h-100"
        />
        <span class="ms-2 font-weight-bold me-2" style="font-size: 20px">Tickity CMS</span>
      </router-link>
    </div>
    <hr class="mt-0 horizontal dark" />
    <sidenav-list :cardBg="custom_class" />
  </aside>
</template>
<script>
  import SidenavList from './SidenavList.vue';
  import logo from '@/assets/img/logo-ct-dark.png';
  import logoWhite from '@/assets/img/logo-ct.png';
  import tickityLogo from '@/assets/img/icon.svg';

  export default {
    name: 'index-component',
    components: {
      SidenavList,
    },
    data() {
      return {
        logo,
        logoWhite,
        tickityLogo,
      };
    },
    props: ['custom_class', 'layout'],
  };
</script>
