import { addOrgnazier, deleteOrgnazier, getOrgnazier, getOrgnaziers, updateOrgnazier } from '@/api/organizers';

export default {
  namespaced: true,
  state: {
    organizersList: { data: [], pageNumber: 1, pageSize: 10, totalCount: 0 },
    organizerDetails: {},
    progress: {
      organizersList: { loading: false, loadingFailed: false },
      getOrganizers: { loading: false, loadingFailed: false },
      addOrganizers: { loading: false, loadingFailed: false },
      deleteOrganizers: { loading: false, loadingFailed: false },
      updateOrganizers: { loading: false, loadingFailed: false },
    },
    modals: { addOrganizer: false, deleteOrganizer: false, editOrganizer: false },
    organizerId: '',
  },

  actions: {
    loadOrganizersList({ commit, state }, payload) {
      if (state.progress.organizersList.loading) return;
      commit('SET_ORGANIZERS_LIST_LOADING_INPROGRESS', true);
      commit('SET_ORGANIZERS_LIST_LOADING_FAILED', false);
      getOrgnaziers(payload)
        .then((tResp) => {
          commit('SET_ORGANIZERS_LIST_LOADING_INPROGRESS', false);
          commit('SET_ORGANIZERS_LIST_LOADING_FAILED', false);
          commit('APPLY_ORGANIZERS_LIST', tResp.data);
        })
        .catch(() => {
          commit('SET_ORGANIZERS_LIST_LOADING_INPROGRESS', false);
          commit('SET_ORGANIZERS_LIST_LOADING_FAILED', true);
        });
    },

    addOrganizer({ commit, state, dispatch }, payload) {
      if (state.progress.organizersList.loading) return;
      if (state.progress.addOrganizers.loading) return;
      commit('SET_ADD_ORGANIZERS_LOADING_INPROGRESS', true);
      commit('SET_ADD_ADMIN_LOADING_FAILED', false);
      addOrgnazier(payload)
        .then(() => {
          commit('TOGGLE_ADD_ORGANIZER_MODAL');
          commit('SET_ADD_ORGANIZERS_LOADING_INPROGRESS', false);
          commit('SET_ADD_ORGANIZERS_LOADING_FAILED', false);
          dispatch('loadOrganizersList', { pageNumber: 1, pageSize: 10 });
        })
        .catch(() => {
          commit('SET_ADD_ORGANIZERS_LOADING_INPROGRESS', false);
          commit('SET_ADD_ORGANIZERS_LOADING_FAILED', true);
        });
    },

    getOrganizer({ commit, state }) {
      if (state.progress.organizersList.loading) return;
      commit('SET_GET_ORGANIZERS_LOADING_INPROGRESS', true);
      commit('SET_GET_ORGANIZERS_LOADING_FAILED', false);
      getOrgnazier(state.organizerId)
        .then((tResp) => {
          commit('APPLY_GET_ORGANIZER', tResp.data);
          commit('SET_GET_ORGANIZERS_LOADING_INPROGRESS', false);
          commit('SET_GET_ORGANIZERS_LOADING_FAILED', false);
        })
        .catch(() => {
          commit('SET_GET_ORGANIZERS_LOADING_INPROGRESS', false);
          commit('SET_GET_ORGANIZERS_LOADING_FAILED', true);
        });
    },

    deleteOrganizer({ commit, state, dispatch }, payload) {
      if (state.progress.organizersList.loading) return;
      if (state.progress.deleteOrganizers.loading) return;
      commit('SET_DELETE_ORGANIZERS_LOADING_INPROGRESS', true);
      commit('SET_DELETE_ORGANIZERS_LOADING_FAILED', false);
      deleteOrgnazier(payload)
        .then(() => {
          commit('SET_DELETE_ORGANIZERS_LOADING_INPROGRESS', false);
          commit('SET_DELETE_ORGANIZERS_LOADING_FAILED', false);
          commit('TOGGLE_DELETE_ORGANIZER_MODAL');
          dispatch('loadOrganizersList', { pageNumber: 1, pageSize: 10 });
        })
        .catch(() => {
          commit('SET_DELETE_ORGANIZERS_LOADING_INPROGRESS', false);
          commit('SET_DELETE_ORGANIZERS_LOADING_FAILED', true);
        });
    },

    updateOrganizer({ commit, state, dispatch }, payload) {
      if (state.progress.organizersList.loading) return;
      if (state.progress.updateOrganizers.loading) return;
      commit('SET_UPDATE_ORGANIZERS_LOADING_INPROGRESS', true);
      commit('SET_UPDATE_ORGANIZERS_LOADING_FAILED', false);
      updateOrgnazier(payload)
        .then(() => {
          commit('SET_UPDATE_ORGANIZERS_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_ORGANIZERS_LOADING_FAILED', false);
          commit('TOGGLE_EDIT_ORGANIZER_MODAL');
          dispatch('loadOrganizersList', { pageNumber: 1, pageSize: 10 });
        })
        .catch(() => {
          commit('SET_UPDATE_ORGANIZERS_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_ORGANIZERS_LOADING_FAILED', true);
        });
    },
  },

  mutations: {
    SET_ORGANIZERS_LIST_LOADING_INPROGRESS: (state, value) => (state.progress.organizersList.loading = value),
    SET_ORGANIZERS_LIST_LOADING_FAILED: (state, value) => (state.progress.organizersList.loadingFailed = value),

    SET_GET_ORGANIZERS_LOADING_INPROGRESS: (state, value) => (state.progress.getOrganizers.loading = value),
    SET_GET_ORGANIZERS_LOADING_FAILED: (state, value) => (state.progress.getOrganizers.loadingFailed = value),

    SET_ADD_ORGANIZERS_LOADING_INPROGRESS: (state, value) => (state.progress.addOrganizers.loading = value),
    SET_ADD_ORGANIZERS_LOADING_FAILED: (state, value) => (state.progress.addOrganizers.loadingFailed = value),

    SET_DELETE_ORGANIZERS_LOADING_INPROGRESS: (state, value) => (state.progress.deleteOrganizers.loading = value),
    SET_DELETE_ORGANIZERS_LOADING_FAILED: (state, value) => (state.progress.deleteOrganizers.loadingFailed = value),

    SET_UPDATE_ORGANIZERS_LOADING_INPROGRESS: (state, value) => (state.progress.updateOrganizers.loading = value),
    SET_UPDATE_ORGANIZERS_LOADING_FAILED: (state, value) => (state.progress.updateOrganizers.loadingFailed = value),

    APPLY_ORGANIZERS_LIST: (state, payload) => (state.organizersList = payload),

    APPLY_GET_ORGANIZER: (state, payload) => (state.organizerDetails = payload.organizer),

    SET_ORGANIZER_ID: (state, payload) => (state.organizerId = payload),

    TOGGLE_ADD_ORGANIZER_MODAL: (state) => (state.modals.addOrganizer = !state.modals.addOrganizer),
    TOGGLE_EDIT_ORGANIZER_MODAL: (state) => (state.modals.editOrganizer = !state.modals.editOrganizer),
    TOGGLE_DELETE_ORGANIZER_MODAL: (state) => (state.modals.deleteOrganizer = !state.modals.deleteOrganizer),
  },
};
