import axiosInstance from '../axios-instance';

export function getOrgnaziers(paginationParams = { PageNumber: 1, PageSize: 10 }) {
  return axiosInstance.get('cms/organizers', { params: { ...paginationParams } });
}

export function addOrgnazier(payload) {
  return axiosInstance.post('cms/organizers', payload);
}

export function getOrgnazier(id) {
  return axiosInstance.get(`cms/organizers/${id}`);
}

export function updateOrgnazier(payload) {
  return axiosInstance.put(`cms/organizers/${payload.id}`, payload);
}

export function deleteOrgnazier(id) {
  return axiosInstance.delete(`cms/organizers/${id}`);
}
