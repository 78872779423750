import axiosInstance from '../axios-instance';

export function getLocations(customerId) {
  return axiosInstance.get(`cms/locations/${customerId}`);
}

export function getLocation(customerId) {
  return axiosInstance.get(`cms/locations/${customerId}`);
}

export function addLocation(customerId, payload) {
  return axiosInstance.post(`cms/locations/${customerId}`, payload);
}

export function updateLocation(customerId, locationId, payload) {
  return axiosInstance.put(`cms/locations/${customerId}/${locationId}`, payload);
}

export function deleteLocation(customerId, locationId) {
  return axiosInstance.delete(`cms/locations/${customerId}/${locationId}`);
}
