<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto d-flex flex-column justify-content-start align-items-start overflow-hidden"
    style="height: calc(100vh - 135px)"
  >
    <ul class="navbar-nav d-flex justify-content-start align-items-start">
      <li class="nav-item">
        <sidenav-item :class="getRoute() === 'dashboard' ? 'active' : ''" :navText="'Dashboard'" url="/dashboard">
          <template v-slot:icon>
            <i class="fa-solid fa-chart-line text-secondary opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item :class="getRoute() === 'admins' ? 'active' : ''" :navText="'Admins'" url="/admins">
          <template v-slot:icon>
            <i class="fa-solid fa-users-gear text-secondary opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item :class="getRoute() === 'customers' ? 'active' : ''" :navText="'Customers'" url="/customers">
          <template v-slot:icon>
            <i class="fa-solid fa-users text-secondary opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item :class="getRoute() === 'organizers' ? 'active' : ''" :navText="'Organizers'" url="/organizers">
          <template v-slot:icon>
            <i class="fa-solid fa-building-user text-secondary opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item :class="getRoute() === 'events' ? 'active' : ''" :navText="'Events'" url="/events">
          <template v-slot:icon>
            <i class="fa-solid fa-calendar-days text-secondary opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          :class="getRoute() === 'Events-Collection' ? 'active' : ''"
          :navText="'Events Collection'"
          url="/events-collections"
        >
          <template v-slot:icon>
            <i class="fa-solid fa-layer-group text-secondary opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item
          :class="getRoute() === 'event-categories' ? 'active' : ''"
          :navText="'Event Categories'"
          url="/event-categories"
        >
          <template v-slot:icon>
            <i class="fa-solid fa-table-cells-large text-secondary opacity-10"></i>
          </template>
        </sidenav-item>
      </li>

      <li class="nav-item">
        <sidenav-item :class="getRoute() === 'orders' ? 'active' : ''" :navText="'Orders'" url="/orders">
          <template v-slot:icon>
            <i class="fa-solid fa-cart-shopping text-secondary opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>

    <div class="pt-3 mt-auto sidenav-footer w-100">
      <ul class="navbar-nav w-100 d-flex justify-content-start align-items-start">
        <li class="nav-item">
          <sidenav-item class="w-100" navText="Send Notification" url="" @click="ShowNotificationPopUp()">
            <template v-slot:icon>
              <i class="fa-solid fa-bullhorn text-secondary opacity-10"></i>
            </template>
          </sidenav-item>
        </li>
        <li class="nav-item">
          <sidenav-item class="w-100" navText="Sign Out" url="" @click="signOut()">
            <template v-slot:icon>
              <i class="fa-solid fa-right-from-bracket text-secondary opacity-10"></i>
            </template>
          </sidenav-item>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
  import SidenavItem from './SidenavItem.vue';

  export default {
    name: 'SidenavList',
    props: {
      cardBg: String,
    },
    data() {
      return {
        title: 'Tickity',
        controls: 'dashboardsExamples',
        isActive: 'active',
      };
    },
    components: {
      SidenavItem,
    },
    methods: {
      getRoute() {
        const routeArr = this.$route.path.split('/');
        return routeArr[1];
      },
      ShowNotificationPopUp() {
        this.$store.commit('NotificationsStore/TOGGLE_SEND_NOTIFICATION_MODAL');
      },
      signOut() {
        localStorage.clear();
        this.$router.push({ path: '/' });
      },
    },
  };
</script>
