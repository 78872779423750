import { addCustomer, deleteCustomer, getCustomer, getCustomers, updateCustomer } from '@/api/customers';

export default {
  namespaced: true,
  state: {
    customersList: { data: [], pageNumber: 1, pageSize: 10, totalCount: 0 },
    customerDetails: {},
    editCustomerDetails: {},
    filterParams: { Name: null, Phone: null, PageNumber: 1, PageSize: 10 },
    isFilterApplied: false,
    progress: {
      customersList: { loading: false, loadingFailed: false },
      getCustomers: { loading: false, loadingFailed: false },
      addCustomers: { loading: false, loadingFailed: false },
      deleteCustomers: { loading: false, loadingFailed: false },
      updateCustomers: { loading: false, loadingFailed: false },
    },
    modals: { addCustomer: false, deleteCustomer: false, editCustomer: false, filterCustomers: false },
    customerId: '',
  },

  actions: {
    loadCustomersList({ state, commit }) {
      commit('SET_CUSTOMERS_LIST_LOADING_INPROGRESS', true);
      commit('SET_CUSTOMERS_LIST_LOADING_FAILED', false);
      getCustomers(state.filterParams)
        .then((tResp) => {
          commit('SET_CUSTOMERS_LIST_LOADING_INPROGRESS', false);
          commit('SET_CUSTOMERS_LIST_LOADING_FAILED', false);
          commit('APPLY_CUSTOMERS_LIST', tResp.data);
          state.modals.filterCustomers = false;
        })
        .catch(() => {
          commit('SET_CUSTOMERS_LIST_LOADING_INPROGRESS', false);
          commit('SET_CUSTOMERS_LIST_LOADING_FAILED', true);
        });
    },

    addCustomer({ commit, state, dispatch }, payload) {
      if (state.progress.customersList.loading) return;
      if (state.progress.addCustomers.loading) return;
      commit('SET_ADD_CUSTOMERS_LOADING_INPROGRESS', true);
      commit('SET_ADD_CUSTOMERS_LOADING_FAILED', false);
      addCustomer(payload)
        .then((tResp) => {
          if (tResp.status >= 400) return;
          commit('TOGGLE_ADD_CUSTOMER_MODAL');
          commit('SET_ADD_CUSTOMERS_LOADING_INPROGRESS', false);
          commit('SET_ADD_CUSTOMERS_LOADING_FAILED', false);
          dispatch('loadCustomersList', { pageNumber: 1, pageSize: 10 });
        })
        .catch(() => {
          commit('SET_ADD_CUSTOMERS_LOADING_INPROGRESS', false);
          commit('SET_ADD_CUSTOMERS_LOADING_FAILED', true);
        });
    },

    getCustomer({ commit, state }) {
      if (state.progress.customersList.loading) return;
      commit('SET_GET_CUSTOMERS_LOADING_INPROGRESS', true);
      commit('SET_GET_CUSTOMERS_LOADING_FAILED', false);
      getCustomer(state.customerId)
        .then((tResp) => {
          commit('APPLY_GET_CUSTOMER', tResp.data);
          commit('SET_GET_CUSTOMERS_LOADING_INPROGRESS', false);
          commit('SET_GET_CUSTOMERS_LOADING_FAILED', false);
        })
        .catch(() => {
          commit('SET_GET_CUSTOMERS_LOADING_INPROGRESS', false);
          commit('SET_GET_CUSTOMERS_LOADING_FAILED', true);
        });
    },

    deleteCustomer({ commit, state, dispatch }) {
      if (state.progress.customersList.loading) return;
      if (state.progress.deleteCustomers.loading) return;
      commit('SET_DELETE_CUSTOMERS_LOADING_INPROGRESS', true);
      commit('SET_DELETE_CUSTOMERS_LOADING_FAILED', false);
      deleteCustomer(state.customerId)
        .then(() => {
          commit('SET_DELETE_CUSTOMERS_LOADING_INPROGRESS', false);
          commit('SET_DELETE_CUSTOMERS_LOADING_FAILED', false);
          commit('TOGGLE_DELETE_CUSTOMER_MODAL');
          dispatch('loadCustomersList', { pageNumber: 1, pageSize: 10 });
        })
        .catch(() => {
          commit('SET_DELETE_CUSTOMERS_LOADING_INPROGRESS', false);
          commit('SET_DELETE_CUSTOMERS_LOADING_FAILED', true);
        });
    },

    updateCustomer({ commit, state, dispatch }, payload) {
      if (state.progress.customersList.loading) return;
      if (state.progress.updateCustomers.loading) return;
      commit('SET_UPDATE_CUSTOMER_LOADING_INPROGRESS', true);
      commit('SET_UPDATE_CUSTOMER_LOADING_FAILED', false);
      updateCustomer(payload)
        .then(() => {
          commit('SET_UPDATE_CUSTOMER_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_CUSTOMER_LOADING_FAILED', false);
          commit('TOGGLE_EDIT_CUSTOMER_MODAL');
          dispatch('loadCustomersList', { pageNumber: 1, pageSize: 10 });
        })
        .catch(() => {
          commit('SET_UPDATE_CUSTOMER_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_CUSTOMER_LOADING_FAILED', true);
        });
    },
  },

  mutations: {
    SET_CUSTOMERS_LIST_LOADING_INPROGRESS: (state, value) => (state.progress.customersList.loading = value),
    SET_CUSTOMERS_LIST_LOADING_FAILED: (state, value) => (state.progress.customersList.loadingFailed = value),

    SET_GET_CUSTOMERS_LOADING_INPROGRESS: (state, value) => (state.progress.getCustomers.loading = value),
    SET_GET_CUSTOMERS_LOADING_FAILED: (state, value) => (state.progress.getCustomers.loadingFailed = value),

    SET_ADD_CUSTOMERS_LOADING_INPROGRESS: (state, value) => (state.progress.addCustomers.loading = value),
    SET_ADD_CUSTOMERS_LOADING_FAILED: (state, value) => (state.progress.addCustomers.loadingFailed = value),

    SET_DELETE_CUSTOMERS_LOADING_INPROGRESS: (state, value) => (state.progress.deleteCustomers.loading = value),
    SET_DELETE_CUSTOMERS_LOADING_FAILED: (state, value) => (state.progress.deleteCustomers.loadingFailed = value),

    SET_UPDATE_CUSTOMER_LOADING_INPROGRESS: (state, value) => (state.progress.updateCustomers.loading = value),
    SET_UPDATE_CUSTOMER_LOADING_FAILED: (state, value) => (state.progress.updateCustomers.loadingFailed = value),

    APPLY_CUSTOMERS_LIST: (state, payload) => (state.customersList = payload),
    APPLY_GET_CUSTOMER: (state, payload) => (state.customerDetails = payload.customer),
    APPLY_EDIT_CUSTOMER_DETAILS: (state, payload) => (state.editCustomerDetails = payload),
    SET_CUSTOMER_ID: (state, payload) => (state.customerId = payload),
    APPLY_FILTER_TRIGGER: (state, payload) => (state.isFilterApplied = payload),
    APPLY_FILTER_CUSTOMER: (state, payload) => {
      state.filterParams = {
        pageNumber: payload.PageNumber ?? 1,
        PageSize: payload.PageSize ?? 10,
        Name: payload.Name ?? null,
        Phone: payload.Phone ?? null,
      };
    },

    TOGGLE_ADD_CUSTOMER_MODAL: (state) => (state.modals.addCustomer = !state.modals.addCustomer),
    TOGGLE_EDIT_CUSTOMER_MODAL: (state) => (state.modals.editCustomer = !state.modals.editCustomer),
    TOGGLE_DELETE_CUSTOMER_MODAL: (state) => (state.modals.deleteCustomer = !state.modals.deleteCustomer),
    TOGGLE_FILTER_CUSTOMER_MODAL: (state) => (state.modals.filterCustomers = !state.modals.filterCustomers),
  },
};
