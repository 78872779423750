import axiosInstance from '../axios-instance';

export function getEvents(paginationParams = { PageNumber: 1, PageSize: 10 }) {
  return axiosInstance.get('cms/events', { params: { ...paginationParams } });
}

export function getActiveEvents() {
  return axiosInstance.get('cms/events/active');
}

export function addEvent(payload) {
  return axiosInstance.post('cms/events', payload);
}

export function getEvent(id) {
  return axiosInstance.get(`cms/events/${id}`);
}

export function updateEvent(payload) {
  return axiosInstance.put(`cms/events/${payload.id}`, payload);
}

export function deleteEvent(id) {
  return axiosInstance.delete(`cms/events/${id}`);
}
