import axiosInstance from '../axios-instance';

export function getCategories() {
  return axiosInstance.get('cms/categories');
}

export function addCategory(payload) {
  return axiosInstance.post('cms/categories', payload);
}

export function getCategory(id) {
  return axiosInstance.get(`cms/categories/${id}`);
}

export function updateCategory(id, payload) {
  return axiosInstance.put(`cms/categories/${id}`, payload);
}

export function deleteCategory(id) {
  return axiosInstance.delete(`cms/categories/${id}`);
}
