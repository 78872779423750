import { addScanner, daleteScanner, getScanner, getScanners, updateScanner } from '../../api/scanners';

export default {
  namespaced: true,
  state: {
    scannersList: { data: [], pageNumber: 1, pageSize: 10, totalCount: 0 },
    progress: {
      scannerList: { loading: false, loadingFailed: false },
      getScanner: { loading: false, loadingFailed: false },
      addScanner: { loading: false, loadingFailed: false },
      deleteScanner: { loading: false, loadingFailed: false },
      updateScanner: { loading: false, loadingFailed: false },
    },
    modals: { addScanner: false, deleteScanner: false, editScanner: false },
    scannerDetails: {},
    editScannerDetails: {},
    scannerId: '',
    eventId: '',
  },

  actions: {
    loadScannerList({ commit, state }, payload) {
      if (state.progress.scannerList.loading) return;
      commit('SET_SCANNER_LIST_LOADING_INPROGRESS', true);
      commit('SET_SCANNER_LIST_LOADING_FAILED', false);
      getScanners(payload, state.eventId)
        .then((tResp) => {
          commit('SET_SCANNER_LIST_LOADING_INPROGRESS', false);
          commit('SET_SCANNER_LIST_LOADING_FAILED', false);
          commit('APPLY_SCANNERS_LIST', tResp.data);
        })
        .catch(() => {
          commit('SET_SCANNER_LIST_LOADING_INPROGRESS', false);
          commit('SET_SCANNER_LIST_LOADING_FAILED', true);
        });
    },

    addEventScanner({ commit, state, dispatch }, payload) {
      if (state.progress.scannerList.loading) return;
      if (state.progress.addScanner.loading) return;
      commit('SET_ADD_SCANNER_LOADING_INPROGRESS', true);
      commit('SET_ADD_SCANNER_LOADING_FAILED', false);
      addScanner(payload)
        .then(() => {
          commit('SET_ADD_SCANNER_LOADING_INPROGRESS', false);
          commit('SET_ADD_SCANNER_LOADING_FAILED', false);
          commit('TOGGLE_ADD_SCANNER_MODAL');
          dispatch('loadScannerList', { pageNumber: 1, pageSize: 10 });
        })
        .catch(() => {
          commit('SET_ADD_SCANNER_LOADING_INPROGRESS', false);
          commit('SET_ADD_SCANNER_LOADING_FAILED', true);
        });
    },

    getEventScanner({ commit, state }) {
      if (state.progress.getScanner.loading) return;
      commit('SET_GET_SCANNER_LOADING_INPROGRESS', true);
      commit('SET_GET_SCANNER_LOADING_FAILED', false);
      getScanner(state.scannerId)
        .then((tResp) => {
          commit('APPLY_GET_SCANNER', tResp.data);
          commit('SET_GET_SCANNER_LOADING_INPROGRESS', false);
          commit('SET_GET_SCANNER_LOADING_FAILED', false);
        })
        .catch(() => {
          commit('SET_GET_SCANNER_LOADING_INPROGRESS', false);
          commit('SET_GET_SCANNER_LOADING_FAILED', true);
        });
    },
    deleteEventScanner({ commit, state, dispatch }, payload) {
      if (state.progress.scannerList.loading) return;
      if (state.progress.deleteScanner.loading) return;
      commit('SET_DELETE_SCANNER_LOADING_INPROGRESS', true);
      commit('SET_DELETE_SCANNER_LOADING_FAILED', false);
      daleteScanner(payload)
        .then(() => {
          commit('SET_DELETE_SCANNER_LOADING_INPROGRESS', false);
          commit('SET_DELETE_SCANNER_LOADING_FAILED', false);
          commit('TOGGLE_DELETE_SCANNER_MODAL');
          dispatch('loadScannerList', { pageNumber: 1, pageSize: 10 });
        })
        .catch(() => {
          commit('SET_DELETE_SCANNER_LOADING_INPROGRESS', false);
          commit('SET_DELETE_SCANNER_LOADING_FAILED', true);
        });
    },

    updateEventScanner({ commit, state, dispatch }, payload) {
      if (state.progress.scannerList.loading) return;
      if (state.progress.updateScanner.loading) return;
      commit('SET_UPDATE_SCANNER_LOADING_INPROGRESS', true);
      commit('SET_UPDATE_SCANNER_LOADING_FAILED', false);
      updateScanner(payload)
        .then(() => {
          commit('SET_UPDATE_SCANNER_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_SCANNER_LOADING_FAILED', false);
          commit('TOGGLE_EDIT_SCANNER_MODAL');
          dispatch('loadScannerList', { pageNumber: 1, pageSize: 10 });
        })
        .catch(() => {
          commit('SET_UPDATE_SCANNER_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_SCANNER_LOADING_FAILED', true);
        });
    },
  },

  mutations: {
    SET_SCANNER_LIST_LOADING_INPROGRESS: (state, value) => (state.progress.scannerList.loading = value),
    SET_SCANNER_LIST_LOADING_FAILED: (state, value) => (state.progress.scannerList.loadingFailed = value),

    SET_GET_SCANNER_LOADING_INPROGRESS: (state, value) => (state.progress.getScanner.loading = value),
    SET_GET_SCANNER_LOADING_FAILED: (state, value) => (state.progress.getScanner.loadingFailed = value),

    SET_ADD_SCANNER_LOADING_INPROGRESS: (state, value) => (state.progress.addScanner.loading = value),
    SET_ADD_SCANNER_LOADING_FAILED: (state, value) => (state.progress.addScanner.loadingFailed = value),

    SET_DELETE_SCANNER_LOADING_INPROGRESS: (state, value) => (state.progress.deleteScanner.loading = value),
    SET_DELETE_SCANNER_LOADING_FAILED: (state, value) => (state.progress.deleteScanner.loadingFailed = value),

    SET_UPDATE_SCANNER_LOADING_INPROGRESS: (state, value) => (state.progress.updateScanner.loading = value),
    SET_UPDATE_SCANNER_LOADING_FAILED: (state, value) => (state.progress.updateScanner.loadingFailed = value),

    APPLY_SCANNERS_LIST: (state, payload) => (state.scannersList = payload),
    APPLY_GET_SCANNER: (state, payload) => (state.scannerDetails = payload.scanner),
    APPLY_SCANNER_DETAILS: (state, payload) => (state.editScannerDetails = payload),

    SET_SCANNER_ID: (state, payload) => (state.scannerId = payload),
    SET_EVENT_ID: (state, payload) => (state.eventId = payload),

    TOGGLE_ADD_SCANNER_MODAL: (state) => (state.modals.addScanner = !state.modals.addScanner),
    TOGGLE_EDIT_SCANNER_MODAL: (state) => (state.modals.editScanner = !state.modals.editScanner),
    TOGGLE_DELETE_SCANNER_MODAL: (state) => (state.modals.deleteScanner = !state.modals.deleteScanner),
  },
};
