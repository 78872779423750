import axiosInstance from '../axios-instance';

export function getCollections() {
  return axiosInstance.get('cms/collections');
}

export function addCollection(payload) {
  return axiosInstance.post('cms/collections', payload);
}

export function updateCollection(payload) {
  return axiosInstance.put(`cms/collections/${payload.id}`, payload);
}

export function deleteCollection(id) {
  return axiosInstance.delete(`cms/collections/${id}`);
}
