import axiosInstance from '../axios-instance';

export function getTicketsType(eventId) {
  return axiosInstance.get(`cms/tickets-types?EventId=${eventId}`);
}

export function addTicketTypes(payload) {
  return axiosInstance.post('cms/tickets-types', payload);
}

export function getTicketTypes(id) {
  return axiosInstance.get(`cms/tickets-types/${id}`);
}

export function updateTicketType(payload) {
  return axiosInstance.put(`cms/tickets-types/${payload.id}`, payload);
}

export function daleteTicketType(id) {
  return axiosInstance.delete(`cms/tickets-types/${id}`);
}
