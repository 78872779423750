import { addCategory, deleteCategory, getCategories, getCategory, updateCategory } from '@/api/categories';

export default {
  namespaced: true,
  state: {
    categories: [],
    progress: {
      categoriesList: { loading: false, loadingFailed: false },
      getCategory: { loading: false, loadingFailed: false },
      addCategory: { loading: false, loadingFailed: false },
      deleteCategory: { loading: false, loadingFailed: false },
      updateCategory: { loading: false, loadingFailed: false },
    },
    modals: { addCategory: false, editCategory: false, deleteCategory: false },
    categoryDetails: {},
    editCategoryDetails: {},
    categoryId: '',
  },
  actions: {
    loadCategoriesList({ commit, state }) {
      if (state.progress.categoriesList.loading) return;
      commit('SET_CATEGORIES_LIST_LOADING_INPROGRESS', true);
      commit('SET_CATEGORIES_LIST_LOADING_FAILED', false);
      getCategories()
        .then((tResp) => {
          commit('SET_CATEGORIES_LIST_LOADING_INPROGRESS', false);
          commit('SET_CATEGORIES_LIST_LOADING_FAILED', false);
          commit('APPLY_CATEGORIES_LIST', tResp.data);
        })
        .catch(() => {
          commit('SET_CATEGORIES_LIST_LOADING_INPROGRESS', false);
          commit('SET_CATEGORIES_LIST_LOADING_FAILED', true);
        });
    },

    AddCategory({ commit, state, dispatch }, payload) {
      if (state.progress.addCategory.loading) return;
      commit('SET_ADD_CATEGORY_LOADING_INPROGRESS', true);
      commit('SET_ADD_CATEGORY_LOADING_FAILED', false);
      addCategory(payload)
        .then(() => {
          commit('SET_ADD_CATEGORY_LOADING_INPROGRESS', false);
          commit('SET_ADD_CATEGORY_LOADING_FAILED', false);
          dispatch('loadCategoriesList');
          commit('TOGGLE_ADD_CATEGORY_MODAL');
        })
        .catch(() => {
          commit('SET_ADD_CATEGORY_LOADING_INPROGRESS', false);
          commit('SET_ADD_CATEGORY_LOADING_FAILED', true);
        });
    },

    loadCategoty({ commit, state }) {
      if (state.progress.getCategory.loading) return;
      commit('SET_GET_CATEGORY_LOADING_INPROGRESS', true);
      commit('SET_GET_CATEGORY_LOADING_FAILED', false);
      getCategory()
        .then((tResp) => {
          commit('APPLY_CATEGORIES_DETAILS', tResp.data);
          commit('SET_GET_CATEGORY_LOADING_INPROGRESS', false);
          commit('SET_GET_CATEGORY_LOADING_FAILED', false);
        })
        .catch(() => {
          commit('SET_GET_CATEGORY_LOADING_INPROGRESS', false);
          commit('SET_GET_CATEGORY_LOADING_FAILED', true);
        });
    },

    deleteCategory({ commit, state, dispatch }) {
      if (state.progress.deleteCategory.loading) return;
      commit('SET_DELETE_CATEGORY_LOADING_INPROGRESS', true);
      commit('SET_DELETE_CATEGORY_LOADING_FAILED', false);
      deleteCategory(state.categoryId)
        .then(() => {
          commit('SET_DELETE_CATEGORY_LOADING_INPROGRESS', false);
          commit('SET_DELETE_CATEGORY_LOADING_FAILED', false);
          commit('TOGGLE_DELETE_CATEGORY_MODAL');
          dispatch('loadCategoriesList');
        })
        .catch(() => {
          commit('SET_DELETE_CATEGORY_LOADING_INPROGRESS', false);
          commit('SET_DELETE_CATEGORY_LOADING_FAILED', true);
        });
    },

    updateCategory({ commit, state, dispatch }, payload) {
      if (state.progress.updateCategory.loading) return;
      commit('SET_UPDATE_CATEGORY_LOADING_INPROGRESS', true);
      commit('SET_UPDATE_CATEGORY_LOADING_FAILED', false);
      updateCategory(state.editCategoryDetails.id, payload)
        .then(() => {
          commit('SET_UPDATE_CATEGORY_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_CATEGORY_LOADING_FAILED', false);
          commit('TOGGLE_EDIT_CATEGORY_MODAL');
          dispatch('loadCategoriesList');
        })
        .catch(() => {
          commit('SET_UPDATE_CATEGORY_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_CATEGORY_LOADING_FAILED', true);
        });
    },
  },
  mutations: {
    SET_CATEGORIES_LIST_LOADING_INPROGRESS: (state, value) => (state.progress.categoriesList.loading = value),
    SET_CATEGORIES_LIST_LOADING_FAILED: (state, value) => (state.progress.categoriesList.loadingFailed = value),

    SET_GET_CATEGORY_LOADING_INPROGRESS: (state, value) => (state.progress.getCategory.loading = value),
    SET_GET_CATEGORY_LOADING_FAILED: (state, value) => (state.progress.getCategory.loadingFailed = value),

    SET_ADD_CATEGORY_LOADING_INPROGRESS: (state, value) => (state.progress.addCategory.loading = value),
    SET_ADD_CATEGORY_LOADING_FAILED: (state, value) => (state.progress.addCategory.loadingFailed = value),

    SET_DELETE_CATEGORY_LOADING_INPROGRESS: (state, value) => (state.progress.deleteCategory.loading = value),
    SET_DELETE_CATEGORY_LOADING_FAILED: (state, value) => (state.progress.deleteCategory.loadingFailed = value),

    SET_UPDATE_CATEGORY_LOADING_INPROGRESS: (state, value) => (state.progress.updateCategory.loading = value),
    SET_UPDATE_CATEGORY_LOADING_FAILED: (state, value) => (state.progress.updateCategory.loadingFailed = value),

    APPLY_CATEGORIES_LIST: (state, payload) => (state.categories = payload.categories),
    APPLY_CATEGORIES_DETAILS: (state, payload) => (state.categoryDetails = payload),
    APPLY_EDIT_CATEGORIY: (state, payload) => (state.editCategoryDetails = payload),
    SET_CATEGORY_ID: (state, payload) => (state.categoryId = payload),

    TOGGLE_ADD_CATEGORY_MODAL: (state) => (state.modals.addCategory = !state.modals.addCategory),
    TOGGLE_EDIT_CATEGORY_MODAL: (state) => (state.modals.editCategory = !state.modals.editCategory),
    TOGGLE_DELETE_CATEGORY_MODAL: (state) => (state.modals.deleteCategory = !state.modals.deleteCategory),
  },
};
