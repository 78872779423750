import { addEvent, deleteEvent, getActiveEvents, getEvent, getEvents, updateEvent } from '@/api/events';
import router from '@/router';

export default {
  namespaced: true,
  state: {
    eventList: { data: [], pageNumber: 1, pageSize: 10, totalCount: 0 },
    activeEventList: { data: [], pageNumber: 1, pageSize: 10, totalCount: 0 },
    eventDetails: {},
    progress: {
      eventList: { loading: false, loadingFailed: false },
      activeEventList: { loading: false, loadingFailed: false },
      getEvent: { loading: false, loadingFailed: false },
      addEvent: { loading: false, loadingFailed: false },
      deleteEvent: { loading: false, loadingFailed: false },
      updateEvent: { loading: false, loadingFailed: false },
    },
    modals: { addEvent: false, deleteEvent: false, editEvent: false },
    eventId: '',
  },

  actions: {
    loadEventList({ commit, state }, payload) {
      if (state.progress.eventList.loading) return;
      commit('SET_EVENT_LIST_LOADING_INPROGRESS', true);
      commit('SET_EVENT_LIST_LOADING_FAILED', false);
      getEvents(payload)
        .then((tResp) => {
          commit('SET_EVENT_LIST_LOADING_INPROGRESS', false);
          commit('SET_EVENT_LIST_LOADING_FAILED', false);
          commit('APPLY_EVENT_LIST', tResp.data);
        })
        .catch(() => {
          commit('SET_EVENT_LIST_LOADING_INPROGRESS', false);
          commit('SET_EVENT_LIST_LOADING_FAILED', true);
        });
    },

    loadActiveEvents({ commit, state }, payload) {
      if (state.progress.activeEventList.loading) return;
      commit('SET_ACTIVE_EVENT_LIST_LOADING_INPROGRESS', true);
      commit('SET_ACTIVE_EVENT_LIST_LOADING_FAILED', false);
      getActiveEvents(payload)
        .then((tResp) => {
          commit('SET_ACTIVE_EVENT_LIST_LOADING_INPROGRESS', false);
          commit('SET_ACTIVE_EVENT_LIST_LOADING_FAILED', false);
          commit('APPLY_ACTIVE_EVENT_LIST', tResp.data);
        })
        .catch(() => {
          commit('SET_ACTIVE_EVENT_LIST_LOADING_INPROGRESS', false);
          commit('SET_ACTIVE_EVENT_LIST_LOADING_FAILED', true);
        });
    },

    addEvent({ commit, state }, payload) {
      if (state.progress.eventList.loading) return;
      if (state.progress.addEvent.loading) return;
      commit('SET_ADD_EVENT_LOADING_INPROGRESS', true);
      commit('SET_ADD_EVENT_LOADING_FAILED', false);
      addEvent(payload)
        .then((tResp) => {
          if (tResp.status !== 200) return;
          commit('SET_ADD_EVENT_LOADING_INPROGRESS', false);
          commit('SET_ADD_EVENT_LOADING_FAILED', false);
          router.push(`/events/${tResp.data.id}`);
        })
        .catch(() => {
          commit('SET_ADD_EVENT_LOADING_INPROGRESS', false);
          commit('SET_ADD_EVENT_LOADING_FAILED', true);
        });
    },

    getEvent({ commit, state }, payload) {
      if (state.progress.eventList.loading) return;
      commit('SET_GET_EVENT_LOADING_INPROGRESS', true);
      commit('SET_GET_EVENT_LOADING_FAILED', false);
      state.eventDetails = {};
      getEvent(payload.eventId)
        .then((tResp) => {
          commit('APPLY_GET_EVENT', tResp.data);
          commit('SET_GET_EVENT_LOADING_INPROGRESS', false);
          commit('SET_GET_EVENT_LOADING_FAILED', false);
        })
        .catch(() => {
          commit('SET_GET_EVENT_LOADING_INPROGRESS', false);
          commit('SET_GET_EVENT_LOADING_FAILED', true);
        });
    },

    deleteEvent({ commit, state, dispatch }) {
      if (state.progress.eventList.loading) return;
      if (state.progress.deleteEvent.loading) return;
      commit('SET_DELETE_EVENT_LOADING_INPROGRESS', true);
      commit('SET_DELETE_EVENT_LOADING_FAILED', false);

      deleteEvent(state.eventId)
        .then(() => {
          commit('SET_DELETE_EVENT_LOADING_INPROGRESS', false);
          commit('SET_DELETE_EVENT_LOADING_FAILED', false);
          commit('TOGGLE_DELETE_EVENT_MODAL');
          state.eventList.data = state.eventList.data.filter((event) => {
            if (event.id !== state.eventId) return event;
          });
          dispatch('loadEventList');
        })
        .catch(() => {
          commit('SET_DELETE_EVENT_LOADING_INPROGRESS', false);
          commit('SET_DELETE_EVENT_LOADING_FAILED', true);
        });
    },

    updateEvent({ commit, state }, payload) {
      if (state.progress.eventList.loading) return;
      if (state.progress.updateEvent.loading) return;
      commit('SET_UPDATE_EVENT_LOADING_INPROGRESS', true);
      commit('SET_UPDATE_EVENT_LOADING_FAILED', false);
      updateEvent(payload)
        .then((tResp) => {
          if (tResp.status !== 200) return;
          commit('SET_UPDATE_EVENT_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_EVENT_LOADING_FAILED', false);
          router.push(`/events/${tResp.data.id}`);
        })
        .catch(() => {
          commit('SET_UPDATE_EVENT_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_EVENT_LOADING_FAILED', true);
        });
    },
  },

  mutations: {
    SET_EVENT_LIST_LOADING_INPROGRESS: (state, value) => (state.progress.eventList.loading = value),
    SET_EVENT_LIST_LOADING_FAILED: (state, value) => (state.progress.eventList.loadingFailed = value),

    SET_ACTIVE_EVENT_LIST_LOADING_INPROGRESS: (state, value) => (state.progress.activeEventList.loading = value),
    SET_ACTIVE_EVENT_LIST_LOADING_FAILED: (state, value) => (state.progress.activeEventList.loadingFailed = value),

    SET_ADD_EVENT_LOADING_INPROGRESS: (state, value) => (state.progress.addEvent.loading = value),
    SET_ADD_EVENT_LOADING_FAILED: (state, value) => (state.progress.addEvent.loadingFailed = value),

    SET_DELETE_EVENT_LOADING_INPROGRESS: (state, value) => (state.progress.deleteEvent.loading = value),
    SET_DELETE_EVENT_LOADING_FAILED: (state, value) => (state.progress.deleteEvent.loadingFailed = value),

    SET_GET_EVENT_LOADING_INPROGRESS: (state, value) => (state.progress.getEvent.loading = value),
    SET_GET_EVENT_LOADING_FAILED: (state, value) => (state.progress.getEvent.loadingFailed = value),

    SET_UPDATE_EVENT_LOADING_INPROGRESS: (state, value) => (state.progress.updateEvent.loading = value),
    SET_UPDATE_EVENT_LOADING_FAILED: (state, value) => (state.progress.updateEvent.loadingFailed = value),

    APPLY_EVENT_LIST: (state, payload) => (state.eventList = payload),
    APPLY_ACTIVE_EVENT_LIST: (state, payload) => (state.activeEventList = payload),
    APPLY_GET_EVENT: (state, payload) => (state.eventDetails = payload.event),
    SET_EVENT_ID: (state, eventId) => (state.eventId = eventId),

    TOGGLE_ADD_EVENT_MODAL: (state) => (state.modals.addEvent = !state.modals.addEvent),
    TOGGLE_EDIT_EVENT_MODAL: (state) => (state.modals.editEvent = !state.modals.editEvent),
    TOGGLE_DELETE_EVENT_MODAL: (state) => (state.modals.deleteEvent = !state.modals.deleteEvent),
  },
};
