import axiosInstance from '../axios-instance';

export function getOrders(filterParams = { PageNumber: 1, PageSize: 10 }) {
  return axiosInstance.get(`cms/orders`, { params: { ...filterParams } });
}

export function exportOrders(filterParams = { PageNumber: 1, PageSize: 10 }) {
  return axiosInstance.get(`cms/orders/export`, { params: { ...filterParams } });
}

export function addOrder(orderDetails) {
  return axiosInstance.post(`cms/orders`, orderDetails);
}

export function getOrder(orderId) {
  return axiosInstance.get(`cms/orders/${orderId}`);
}

export function updateOrderStatus(orderId, payload) {
  return axiosInstance.put(`cms/orders/${orderId}/status`, payload);
}
