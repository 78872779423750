import { createApp } from 'vue';
import App from './App.vue';
import DashboardInit from './dashboard-init';
import i18n from './localization-instance';
import router from './router';
import store from './store';
import VueDatePicker from '@vuepic/vue-datepicker';
import vSelect from 'vue-select';

import '@vuepic/vue-datepicker/dist/main.css';
import 'vue-select/dist/vue-select.css';

const appInstance = createApp(App);
appInstance.use(store);
appInstance.use(router);
appInstance.use(DashboardInit);
appInstance.use(i18n);
appInstance.mount('#app');
appInstance.component('VueDatePicker', VueDatePicker);
appInstance.component('v-select', vSelect);
