import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'default',
    redirect: '/signin',
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard/Dashboard'),
  },
  {
    path: '/admins',
    name: 'Admins',
    component: () => import('../views/admins/admins'),
  },
  {
    path: '/customers',
    name: 'Customers',
    component: () => import('../views/customers/customers'),
  },
  {
    path: '/customers/:id',
    name: 'Customer Info',
    component: () => import('../views/customer-details/customer-details'),
  },
  {
    path: '/organizers',
    name: 'Organizers',
    component: () => import('../views/organizers/organizers'),
  },
  {
    path: '/events',
    name: 'Events',
    children: [
      { path: '', name: 'Events', component: () => import('../views/events/events') },
      { path: 'create', name: 'New Event', component: () => import('../views/events/event-action/create-event') },
      { path: 'edit/:eventId', name: 'Edit Event', component: () => import('../views/events/event-action/edit-event') },
      { path: ':id', name: 'Event Details', component: () => import('../views/events/event-details/event-details') },
    ],
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('../views/orders/orders'),
  },
  {
    path: '/event-categories',
    name: 'Event-Categories',
    component: () => import('../views/events-categories/events-categories'),
  },
  {
    path: '/events-collections',
    name: 'Events-Collection',
    component: () => import('../views/collections/collection'),
  },
  {
    path: '/signin',
    name: 'Signin',
    component: () => import('../views/Signin/Signin'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
});

export default router;
