import { getFinancialData, getEventsAndCustomersCount } from '@/api/dashboard';

export default {
  namespaced: true,
  state: {
    eventsAndCustomersCount: {
      eventCount: 0,
      activeEventsCount: 0,
      customerCount: 0,
    },
    getFinancial: {
      ordersByDays: [],
      totalOrdersCount: 0,
      totalOrdersPrice: 0,
    },
    RequestStatus: {
      getEventsAndCustomerData: { loading: false, failed: false },
      getOrdersData: { loading: false, failed: false },
    },
  },

  actions: {
    getFinancial({ commit }, payload) {
      commit('SET_LOADING_FINANCIAL_PROGRESS', true);
      commit('SET_LOADING_FINANCIAL_FAILED', false);
      return getFinancialData(payload.from, payload.to)
        .then((tResp) => {
          commit('SET_LOADING_FINANCIAL_PROGRESS', false);
          commit('SET_LOADING_FINANCIAL_FAILED', false);
          tResp.data.ordersByDays = tResp.data.ordersByDays.sort(
            (a, b) => Date.parse(new Date(a.date)) - Date.parse(new Date(b.date))
          );
          commit('SET_FINANCIAL_DATA', tResp.data);
        })
        .catch((error) => {
          console.log(error);
          commit('SET_LOADING_FINANCIAL_PROGRESS', false);
          commit('SET_LOADING_FINANCIAL_FAILED', true);
        });
    },

    getEventsAndCustomersData({ commit }) {
      commit('SET_LOADING_EVENTS_COUNT_PROGRESS', true);
      commit('SET_LOADING_EVENTS_COUNT_FAILED', false);
      return getEventsAndCustomersCount()
        .then((tResp) => {
          commit('SET_LOADING_EVENTS_COUNT_PROGRESS', false);
          commit('SET_LOADING_EVENTS_COUNT_FAILED', false);
          commit('SET_EVENTS_DATA', tResp.data?.dashboard);
        })
        .catch((error) => {
          console.log(error);
          commit('SET_LOADING_EVENTS_COUNT_PROGRESS', false);
          commit('SET_LOADING_EVENTS_COUNT_FAILED', true);
        });
    },
  },

  mutations: {
    SET_LOADING_EVENTS_COUNT_PROGRESS: (state, value) => (state.RequestStatus.getEventsAndCustomerData.loading = value),
    SET_LOADING_EVENTS_COUNT_FAILED: (state, value) => (state.RequestStatus.getEventsAndCustomerData.failed = value),

    SET_LOADING_FINANCIAL_PROGRESS: (state, value) => (state.RequestStatus.getOrdersData.loading = value),
    SET_LOADING_FINANCIAL_FAILED: (state, value) => (state.RequestStatus.getOrdersData.failed = value),

    SET_FINANCIAL_DATA: (state, value) => (state.getFinancial = value),
    SET_EVENTS_DATA: (state, value) => (state.eventsAndCustomersCount = value),
  },
};
