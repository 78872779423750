import { addCollection, deleteCollection, getCollections, updateCollection } from '../../api/collection';

export default {
  namespaced: true,
  state: {
    collectionList: { collections: [] },
    collectionDetails: {},
    progress: {
      collectionList: { loading: false, loadingFailed: false },
      addCollections: { loading: false, loadingFailed: false },
      deleteCollections: { loading: false, loadingFailed: false },
      updateCollections: { loading: false, loadingFailed: false },
    },
    modals: { addCollection: false, deleteCollection: false, editCollection: false },
    editCollectionDetails: {},
    collectionId: '',
  },

  actions: {
    loadCollectionList({ commit, state }, payload) {
      if (state.progress.collectionList.loading) return;
      commit('SET_COLLECTION_LIST_LOADING_INPROGRESS', true);
      commit('SET_COLLECTION_LIST_LOADING_FAILED', false);
      getCollections(payload)
        .then((tResp) => {
          commit('SET_COLLECTION_LIST_LOADING_INPROGRESS', false);
          commit('SET_COLLECTION_LIST_LOADING_FAILED', false);
          commit('APPLY_COLLECTION_LIST', tResp.data);
        })
        .catch(() => {
          commit('SET_COLLECTION_LIST_LOADING_INPROGRESS', false);
          commit('SET_COLLECTION_LIST_LOADING_FAILED', true);
        });
    },

    addCollection({ commit, state, dispatch }, payload) {
      if (state.progress.collectionList.loading) return;
      if (state.progress.addCollections.loading) return;
      commit('SET_ADD_COLLECTION_LOADING_INPROGRESS', true);
      commit('SET_ADD_COLLECTION_LOADING_FAILED', false);
      addCollection(payload)
        .then(() => {
          commit('TOGGLE_ADD_COLLECTION_MODAL');
          commit('SET_ADD_COLLECTION_LOADING_INPROGRESS', false);
          commit('SET_ADD_COLLECTION_LOADING_FAILED', false);
          dispatch('loadCollectionList');
        })
        .catch(() => {
          commit('SET_ADD_COLLECTION_LOADING_INPROGRESS', false);
          commit('SET_ADD_COLLECTION_LOADING_FAILED', true);
        });
    },

    deleteCollection({ commit, state, dispatch }, payload) {
      if (state.progress.collectionList.loading) return;
      if (state.progress.deleteCollections.loading) return;
      commit('SET_DELETE_COLLECTION_LOADING_INPROGRESS', true);
      commit('SET_DELETE_COLLECTION_LOADING_FAILED', false);
      deleteCollection(payload)
        .then(() => {
          commit('SET_DELETE_COLLECTION_LOADING_INPROGRESS', false);
          commit('SET_DELETE_COLLECTION_LOADING_FAILED', false);
          commit('TOGGLE_DELETE_COLLECTION_MODAL');
          dispatch('loadCollectionList');
        })
        .catch(() => {
          commit('SET_DELETE_COLLECTION_LOADING_INPROGRESS', false);
          commit('SET_DELETE_COLLECTION_LOADING_FAILED', true);
        });
    },

    updateCollection({ commit, state, dispatch }, payload) {
      if (state.progress.collectionList.loading) return;
      if (state.progress.updateCollections.loading) return;
      commit('SET_UPDATE_COLLECTION_LOADING_INPROGRESS', true);
      commit('SET_UPDATE_COLLECTION_LOADING_FAILED', false);
      updateCollection(payload)
        .then(() => {
          commit('SET_UPDATE_COLLECTION_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_COLLECTION_LOADING_FAILED', false);
          commit('TOGGLE_EDIT_COLLECTION_MODAL');
          dispatch('loadCollectionList');
        })
        .catch(() => {
          commit('SET_UPDATE_COLLECTION_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_COLLECTION_LOADING_FAILED', true);
        });
    },
  },

  mutations: {
    SET_COLLECTION_LIST_LOADING_INPROGRESS: (state, value) => (state.progress.collectionList.loading = value),
    SET_COLLECTION_LIST_LOADING_FAILED: (state, value) => (state.progress.collectionList.loadingFailed = value),

    SET_ADD_COLLECTION_LOADING_INPROGRESS: (state, value) => (state.progress.addCollections.loading = value),
    SET_ADD_COLLECTION_LOADING_FAILED: (state, value) => (state.progress.addCollections.loadingFailed = value),

    SET_DELETE_COLLECTION_LOADING_INPROGRESS: (state, value) => (state.progress.deleteCollections.loading = value),
    SET_DELETE_COLLECTION_LOADING_FAILED: (state, value) => (state.progress.deleteCollections.loadingFailed = value),

    SET_UPDATE_COLLECTION_LOADING_INPROGRESS: (state, value) => (state.progress.updateCollections.loading = value),
    SET_UPDATE_COLLECTION_LOADING_FAILED: (state, value) => (state.progress.updateCollections.loadingFailed = value),

    APPLY_COLLECTION_LIST: (state, payload) => (state.collectionList = payload),
    SET_COLLECTION_ID: (state, collectionId) => (state.collectionId = collectionId),
    SET_EDIT_COLLECTION_DETAILS: (state, payload) => (state.editCollectionDetails = payload),

    TOGGLE_ADD_COLLECTION_MODAL: (state) => (state.modals.addCollection = !state.modals.addCollection),
    TOGGLE_EDIT_COLLECTION_MODAL: (state) => (state.modals.editCollection = !state.modals.editCollection),
    TOGGLE_DELETE_COLLECTION_MODAL: (state) => (state.modals.deleteCollection = !state.modals.deleteCollection),
  },
};
