import { createStore } from 'vuex';
import AuthenticationStore from './modules/authentication';
import AdminsStore from './modules/admins';
import CategoriesStore from './modules/categories';
import CustomersStore from './modules/customers';
import EventsStore from './modules/events';
import OrdersStore from './modules/orders';
import OrganizersStore from './modules/organizers';
import CollectionStore from './modules/collections';
import TicketTypeStore from './modules/ticketType';
import ScannerStore from './modules/scanner';
import LocationStore from './modules/location';
import DashboardStore from './modules/dashboard';
import NotificationsStore from './modules/notifications';
import { getGovernorate } from '@/api/shared';

export default createStore({
  state: {
    hideConfigButton: false,
    isPinned: true,
    showConfig: false,
    sidebarType: 'bg-white',
    isRTL: false,
    darkMode: false,
    isNavFixed: false,
    isAbsolute: false,
    showNavs: true,
    showSidenav: true,
    showNavbar: true,
    showFooter: true,
    showMain: true,
    layout: 'default',
    toast: false,
    toastText: '',
    governorate: [],
  },
  mutations: {
    toggleConfigurator(state) {
      state.showConfig = !state.showConfig;
    },
    navbarMinimize(state) {
      const sidenav_show = document.querySelector('.g-sidenav-show');

      if (sidenav_show.classList.contains('g-sidenav-hidden')) {
        sidenav_show.classList.remove('g-sidenav-hidden');
        sidenav_show.classList.add('g-sidenav-pinned');
        state.isPinned = true;
      } else {
        sidenav_show.classList.add('g-sidenav-hidden');
        sidenav_show.classList.remove('g-sidenav-pinned');
        state.isPinned = false;
      }
    },
    sidebarType(state, payload) {
      state.sidebarType = payload;
    },
    navbarFixed(state) {
      if (state.isNavFixed === false) {
        state.isNavFixed = true;
      } else {
        state.isNavFixed = false;
      }
    },
    updateToastStatus(state, payload) {
      state.toast = payload;
    },
    updateToastText(state, payload) {
      state.toastText = payload;
    },
  },
  actions: {
    toggleSidebarColor({ commit }, payload) {
      commit('sidebarType', payload);
    },
    getAllGovernorate({ state }) {
      getGovernorate()
        .then((tResp) => {
          state.governorate = tResp.data;
        })
        .catch((tErr) => {
          console.log(tErr);
        });
    },
  },
  getters: {
    getToast(state) {
      return state.toast;
    },
    AllGovernorate(state) {
      return state.governorate;
    },
  },

  modules: {
    AuthenticationStore,
    AdminsStore,
    CategoriesStore,
    CustomersStore,
    EventsStore,
    OrdersStore,
    OrganizersStore,
    CollectionStore,
    TicketTypeStore,
    ScannerStore,
    LocationStore,
    DashboardStore,
    NotificationsStore,
  },
});
