import { addLocation, deleteLocation, getLocation, getLocations, updateLocation } from '@/api/location';

export default {
  namespaced: true,
  state: {
    locationsList: { data: [] },
    locationDetails: { data: [] },
    editLocationDetails: {},
    progress: {
      locationList: { loading: false, loadingFailed: false },
      getLocation: { loading: false, loadingFailed: false },
      addLocation: { loading: false, loadingFailed: false },
      deleteLocation: { loading: false, loadingFailed: false },
      updateLocation: { loading: false, loadingFailed: false },
    },
    modals: { addLocation: false, deleteLocation: false, editLocation: false },
    locationId: '',
    customerId: '',
  },

  actions: {
    loadLocationsList({ commit, state }) {
      if (state.progress.locationList.loading) return;
      state.locationsList.data = [];
      commit('SET_LOCATION_LIST_LOADING_INPROGRESS', true);
      commit('SET_LOCATION_LIST_LOADING_FAILED', false);
      getLocations(state.customerId)
        .then((tResp) => {
          commit('SET_LOCATION_LIST_LOADING_INPROGRESS', false);
          commit('SET_LOCATION_LIST_LOADING_FAILED', false);
          commit('APPLY_LOCATION_LIST', tResp.data);
        })
        .catch(() => {
          commit('SET_LOCATION_LIST_LOADING_INPROGRESS', false);
          commit('SET_LOCATION_LIST_LOADING_FAILED', true);
        });
    },

    addLocation({ commit, state, dispatch }, payload) {
      if (state.progress.locationList.loading) return;
      if (state.progress.getLocation.loading) return;
      commit('SET_ADD_LOCATION_LOADING_INPROGRESS', true);
      commit('SET_ADD_LOCATION_LOADING_FAILED', false);
      addLocation(state.customerId, payload)
        .then(() => {
          commit('TOGGLE_ADD_LOCATION_MODAL');
          commit('SET_ADD_LOCATION_LOADING_INPROGRESS', false);
          commit('SET_ADD_LOCATION_LOADING_FAILED', false);
          dispatch('loadLocationsList');
        })
        .catch(() => {
          commit('SET_ADD_LOCATION_LOADING_INPROGRESS', false);
          commit('SET_ADD_LOCATION_LOADING_FAILED', true);
        });
    },

    getLocation({ commit, state }) {
      commit('SET_GET_LOCATION_LOADING_INPROGRESS', true);
      commit('SET_GET_LOCATION_LOADING_FAILED', false);
      getLocation(state.customerId)
        .then((tResp) => {
          commit('APPLY_GET_LOCATION', tResp.data);
          commit('SET_GET_LOCATION_LOADING_INPROGRESS', false);
          commit('SET_GET_LOCATION_LOADING_FAILED', false);
        })
        .catch(() => {
          commit('SET_GET_LOCATION_LOADING_INPROGRESS', false);
          commit('SET_GET_LOCATION_LOADING_FAILED', true);
        });
    },

    deleteLocation({ commit, state, dispatch }) {
      commit('SET_DELETE_LOCATION_LOADING_INPROGRESS', true);
      commit('SET_DELETE_LOCATION_LOADING_FAILED', false);
      deleteLocation(state.customerId, state.locationId)
        .then(() => {
          commit('SET_DELETE_LOCATION_LOADING_INPROGRESS', false);
          commit('SET_DELETE_LOCATION_LOADING_FAILED', false);
          commit('TOGGLE_DELETE_LOCATION_MODAL');
          dispatch('loadLocationsList');
        })
        .catch(() => {
          commit('SET_DELETE_LOCATION_LOADING_INPROGRESS', false);
          commit('SET_DELETE_LOCATION_LOADING_FAILED', true);
        });
    },

    updateLocation({ commit, state, dispatch }, payload) {
      commit('SET_UPDATE_LOCATION_LOADING_INPROGRESS', true);
      commit('SET_UPDATE_LOCATION_LOADING_FAILED', false);
      updateLocation(state.customerId, state.locationId, payload)
        .then((tResp) => {
          if (tResp) {
            console.log(tResp);
          }
          commit('SET_UPDATE_LOCATION_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_LOCATION_LOADING_FAILED', false);
          commit('TOGGLE_EDIT_LOCATION_MODAL');
          dispatch('loadLocationsList');
        })
        .catch(() => {
          commit('SET_UPDATE_LOCATION_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_LOCATION_LOADING_FAILED', true);
        });
    },
  },

  mutations: {
    SET_LOCATION_LIST_LOADING_INPROGRESS: (state, value) => (state.progress.locationList.loading = value),
    SET_LOCATION_LIST_LOADING_FAILED: (state, value) => (state.progress.locationList.loadingFailed = value),

    SET_GET_LOCATION_LOADING_INPROGRESS: (state, value) => (state.progress.getLocation.loading = value),
    SET_GET_LOCATION_LOADING_FAILED: (state, value) => (state.progress.getLocation.loadingFailed = value),

    SET_ADD_LOCATION_LOADING_INPROGRESS: (state, value) => (state.progress.addLocation.loading = value),
    SET_ADD_LOCATION_LOADING_FAILED: (state, value) => (state.progress.addLocation.loadingFailed = value),

    SET_DELETE_LOCATION_LOADING_INPROGRESS: (state, value) => (state.progress.deleteLocation.loading = value),
    SET_DELETE_LOCATION_LOADING_FAILED: (state, value) => (state.progress.deleteLocation.loadingFailed = value),

    SET_UPDATE_LOCATION_LOADING_INPROGRESS: (state, value) => (state.progress.updateLocation.loading = value),
    SET_UPDATE_LOCATION_LOADING_FAILED: (state, value) => (state.progress.updateLocation.loadingFailed = value),

    APPLY_LOCATION_LIST: (state, payload) => (state.locationsList = payload),
    APPLY_GET_LOCATION: (state, payload) => (state.locationDetails = payload),
    APPLY_EDIT_LOCATION: (state, payload) => (state.editLocationDetails = payload),
    SET_LOCATION_ID: (state, payload) => (state.locationId = payload),
    SET_CUSTOMER_ID: (state, payload) => (state.customerId = payload),

    TOGGLE_ADD_LOCATION_MODAL: (state) => (state.modals.addLocation = !state.modals.addLocation),
    TOGGLE_EDIT_LOCATION_MODAL: (state) => (state.modals.editLocation = !state.modals.editLocation),
    TOGGLE_DELETE_LOCATION_MODAL: (state) => (state.modals.deleteLocation = !state.modals.deleteLocation),
  },
};
