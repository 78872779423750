import { addAdmin, deleteAdmin, getAdmin, getAdmins, resetPasswordForAdmin, updateAdmin } from '@/api/admins';

export default {
  namespaced: true,
  state: {
    adminsList: { data: [], pageNumber: 1, pageSize: 10, totalCount: 0 },
    addedAdminId: '',
    adminDetails: {},
    progress: {
      adminList: { loading: false, loadingFailed: false },
      getAdmin: { loading: false, loadingFailed: false },
      addAdmin: { loading: false, loadingFailed: false },
      deleteAdmin: { loading: false, loadingFailed: false },
      updateAdmin: { loading: false, loadingFailed: false },
      adminResetPassword: { loading: false, loadingFailed: false },
    },
    modals: { addAdmin: false, deleteAdmin: false, editAdmin: false, resetPasswordAdmin: false },
    adminId: '',
  },

  actions: {
    loadAdminList({ commit, state }, payload) {
      if (state.progress.adminList.loading) return;
      commit('SET_ADMIN_LIST_LOADING_INPROGRESS', true);
      commit('SET_ADMIN_LIST_LOADING_FAILED', false);
      getAdmins(payload)
        .then((tResp) => {
          commit('SET_ADMIN_LIST_LOADING_INPROGRESS', false);
          commit('SET_ADMIN_LIST_LOADING_FAILED', false);
          commit('APPLY_ADMINS_LIST', tResp.data);
        })
        .catch(() => {
          commit('SET_ADMIN_LIST_LOADING_INPROGRESS', false);
          commit('SET_ADMIN_LIST_LOADING_FAILED', true);
        });
    },

    addAdmin({ commit, state, dispatch }, payload) {
      if (state.progress.adminList.loading) return;
      if (state.progress.addAdmin.loading) return;
      commit('SET_ADD_ADMIN_LOADING_INPROGRESS', true);
      commit('SET_ADD_ADMIN_LOADING_FAILED', false);
      addAdmin(payload)
        .then((tResp) => {
          commit('APPLY_ADD_ADMIN', tResp.data);
          commit('SET_ADD_ADMIN_LOADING_INPROGRESS', false);
          commit('SET_ADD_ADMIN_LOADING_FAILED', false);
          dispatch('loadAdminList', { pageNumber: 1, pageSize: 10 });
        })
        .catch(() => {
          commit('SET_ADD_ADMIN_LOADING_INPROGRESS', false);
          commit('SET_ADD_ADMIN_LOADING_FAILED', true);
        });
    },

    getAdmin({ commit, state }) {
      if (state.progress.getAdmin.loading) return;
      commit('SET_GET_ADMIN_LOADING_INPROGRESS', true);
      commit('SET_GET_ADMIN_LOADING_FAILED', false);
      getAdmin(state.adminId)
        .then((tResp) => {
          commit('APPLY_GET_ADMIN', tResp.data);
          commit('SET_GET_ADMIN_LOADING_INPROGRESS', false);
          commit('SET_GET_ADMIN_LOADING_FAILED', false);
        })
        .catch(() => {
          commit('SET_GET_ADMIN_LOADING_INPROGRESS', false);
          commit('SET_GET_ADMIN_LOADING_FAILED', true);
        });
    },

    deleteAdmin({ commit, state, dispatch }, payload) {
      if (state.progress.adminList.loading) return;
      if (state.progress.deleteAdmin.loading) return;
      commit('SET_DELETE_ADMIN_LOADING_INPROGRESS', true);
      commit('SET_DELETE_ADMIN_LOADING_FAILED', false);
      deleteAdmin(payload)
        .then(() => {
          commit('SET_DELETE_ADMIN_LOADING_INPROGRESS', false);
          commit('SET_DELETE_ADMIN_LOADING_FAILED', false);
          commit('TOGGLE_DELETE_ADMIN_MODAL');
          dispatch('loadAdminList', { pageNumber: 1, pageSize: 10 });
        })
        .catch(() => {
          commit('SET_DELETE_ADMIN_LOADING_INPROGRESS', false);
          commit('SET_DELETE_ADMIN_LOADING_FAILED', true);
        });
    },

    updateAdmin({ commit, state, dispatch }, payload) {
      if (state.progress.adminList.loading) return;
      if (state.progress.updateAdmin.loading) return;
      commit('SET_UPDATE_ADMIN_LOADING_INPROGRESS', true);
      commit('SET_UPDATE_ADMIN_LOADING_FAILED', false);
      updateAdmin(payload)
        .then(() => {
          commit('SET_UPDATE_ADMIN_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_ADMIN_LOADING_FAILED', false);
          commit('TOGGLE_EDIT_ADMIN_MODAL');
          dispatch('loadAdminList', { pageNumber: 1, pageSize: 10 });
        })
        .catch(() => {
          commit('SET_UPDATE_ADMIN_LOADING_INPROGRESS', false);
          commit('SET_UPDATE_ADMIN_LOADING_FAILED', true);
        });
    },

    resetPasswordForAdmin({ commit, state }, payload) {
      if (state.progress.adminList.loading) return;
      if (state.progress.adminResetPassword.loading) return;
      commit('SET_ADMIN_RESET_PASSWORD_LOADING_INPROGRESS', true);
      commit('SET_ADMIN_RESET_PASSWORD_LOADING_FAILED', false);
      resetPasswordForAdmin(payload)
        .then(() => {
          commit('SET_ADMIN_RESET_PASSWORD_LOADING_INPROGRESS', false);
          commit('SET_ADMIN_RESET_PASSWORD_LOADING_FAILED', false);
          commit('TOGGLE_RESET_PASSWORD_ADMIN_MODAL');
        })
        .catch(() => {
          commit('SET_ADMIN_RESET_PASSWORD_LOADING_INPROGRESS', false);
          commit('SET_ADMIN_RESET_PASSWORD_LOADING_FAILED', true);
        });
    },
  },

  mutations: {
    SET_ADMIN_LIST_LOADING_INPROGRESS: (state, value) => (state.progress.adminList.loading = value),
    SET_ADMIN_LIST_LOADING_FAILED: (state, value) => (state.progress.adminList.loadingFailed = value),

    SET_GET_ADMIN_LOADING_INPROGRESS: (state, value) => (state.progress.getAdmin.loading = value),
    SET_GET_ADMIN_LOADING_FAILED: (state, value) => (state.progress.getAdmin.loadingFailed = value),

    SET_ADD_ADMIN_LOADING_INPROGRESS: (state, value) => (state.progress.addAdmin.loading = value),
    SET_ADD_ADMIN_LOADING_FAILED: (state, value) => (state.progress.addAdmin.loadingFailed = value),

    SET_DELETE_ADMIN_LOADING_INPROGRESS: (state, value) => (state.progress.deleteAdmin.loading = value),
    SET_DELETE_ADMIN_LOADING_FAILED: (state, value) => (state.progress.deleteAdmin.loadingFailed = value),

    SET_UPDATE_ADMIN_LOADING_INPROGRESS: (state, value) => (state.progress.updateAdmin.loading = value),
    SET_UPDATE_ADMIN_LOADING_FAILED: (state, value) => (state.progress.updateAdmin.loadingFailed = value),

    SET_ADMIN_RESET_PASSWORD_LOADING_INPROGRESS: (state, value) => (state.progress.adminResetPassword.loading = value),
    SET_ADMIN_RESET_PASSWORD_LOADING_FAILED: (state, value) =>
      (state.progress.adminResetPassword.loadingFailed = value),

    APPLY_ADMINS_LIST: (state, payload) => (state.adminsList = payload),
    APPLY_ADD_ADMIN: (state, payload) => {
      state.addedAdminId = payload.id;
      state.modals.addAdmin = !state.modals.addAdmin;
    },
    APPLY_GET_ADMIN: (state, payload) => (state.adminDetails = payload.admin),

    SET_ADMIN_ID: (state, payload) => (state.adminId = payload),

    TOGGLE_ADD_ADMIN_MODAL: (state) => (state.modals.addAdmin = !state.modals.addAdmin),
    TOGGLE_EDIT_ADMIN_MODAL: (state) => (state.modals.editAdmin = !state.modals.editAdmin),
    TOGGLE_DELETE_ADMIN_MODAL: (state) => (state.modals.deleteAdmin = !state.modals.deleteAdmin),
    TOGGLE_RESET_PASSWORD_ADMIN_MODAL: (state) => (state.modals.resetPasswordAdmin = !state.modals.resetPasswordAdmin),
  },
};
