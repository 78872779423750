import axios from 'axios';
import router from './router/index';
import { adminRefreshToken } from './api/admin';
import moment from 'moment';
import store from '@/store';

const SkipAuthHeader = ['auth/admin/login', 'auth/admin/refresh'];
const defaultOptions = {
  baseURL: process.env.VUE_APP_BASE_URL,
};
const axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(async (config) => {
  const savedAdminInformation = JSON.parse(localStorage.getItem('admin'));
  if (SkipAuthHeader.includes(config.url)) {
    return config;
  }
  let canDoRefreshToken =
    moment(savedAdminInformation.accessTokenExpiration).isBefore(moment.now()) &&
    moment(savedAdminInformation.refreshTokenExpiration).isAfter(moment.now());

  if (canDoRefreshToken) {
    try {
      const tResp = await adminRefreshToken();
      if (tResp.status >= 400) {
        store.commit('updateToastStatus', true);
        store.commit('updateToastText', tResp.data.message);
        config.headers.Authorization = JSON.parse(localStorage.getItem('admin'))?.accessToken
          ? `Bearer ${JSON.parse(localStorage.getItem('admin'))?.accessToken}`
          : '';
        return config;
      } else {
        localStorage.setItem('admin', JSON.stringify(tResp.data));
        config.headers.Authorization = JSON.parse(localStorage.getItem('admin'))?.accessToken
          ? `Bearer ${JSON.parse(localStorage.getItem('admin'))?.accessToken}`
          : '';
        return config;
      }
    } catch (error) {
      store.commit('updateToastStatus', true);
      store.commit('updateToastText', error.data.message);
      router.push({ name: 'Signin' });
      throw error;
    }
  } else {
    config.headers.Authorization = JSON.parse(localStorage.getItem('admin'))?.accessToken
      ? `Bearer ${JSON.parse(localStorage.getItem('admin'))?.accessToken}`
      : '';
    return config;
  }
});

axiosInstance.interceptors.response.use(
  (config) => config,
  (error) => {
    let ErrorResponse = error.response;
    if (ErrorResponse.status >= 400) {
      store.commit('updateToastStatus', true);
      store.commit('updateToastText', ErrorResponse.data.message ?? ErrorResponse.data.title);
    }
    if (ErrorResponse.status === 401) {
      router.push({ name: 'Signin' });
    }
    throw error;
  }
);

export default axiosInstance;
