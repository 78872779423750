<template>
  <sidenav v-if="$store.state.showSidenav" class="fixed-start" />
  <main class="main-content position-relative max-height-vh-100 h-100 border-radius-lg">
    <navbar
      v-if="$store.state.showNavbar"
      :class="[navClasses]"
      :minNav="navbarMinimize"
      :textWhite="$store.state.isAbsolute ? 'text-white opacity-8' : 'text-white'"
    />
    <router-view />
    <SendNotification v-if="notificationModalStatus" />
    <Toast v-if="toastState" />
    <app-footer v-show="this.$store.state.showFooter" />
  </main>
</template>
<script>
  import AppFooter from '@/components/Footer.vue';
  import Navbar from '@/components/Navbars/Navbar.vue';
  import { mapMutations } from 'vuex';
  import Sidenav from './components/Sidenav/index';
  import Toast from './components/Toast';
  import { defineAsyncComponent } from 'vue';

  export default {
    name: 'App',
    components: {
      Sidenav,
      Navbar,
      AppFooter,
      Toast,
      SendNotification: defineAsyncComponent(() => import('./views/notification/notification.vue')),
    },
    methods: {
      ...mapMutations(['navbarMinimize']),
    },
    computed: {
      navClasses() {
        return {
          'position-sticky bg-white left-auto top-2 z-index-sticky':
            this.$store.state.isNavFixed && !this.$store.state.darkMode,
          'position-sticky bg-default left-auto top-2 z-index-sticky':
            this.$store.state.isNavFixed && this.$store.state.darkMode,
          'position-absolute px-4 mx-0 w-100 z-index-2': this.$store.state.isAbsolute,
          'px-0 mx-4': !this.$store.state.isAbsolute,
        };
      },
      notificationModalStatus() {
        return this.$store.state.NotificationsStore.modals.sendNotification;
      },
      toastState() {
        return this.$store.state.toast;
      },
    },
    mounted() {
      this.$store.dispatch('getAllGovernorate');
      const hasLoggedInAdmin = !!localStorage.getItem('admin');
      console.log(hasLoggedInAdmin);
      if (!hasLoggedInAdmin) {
        this.$store.state.hideConfigButton = true;
        this.$store.state.showNavbar = false;
        this.$store.state.showSidenav = false;
        this.$store.state.showFooter = false;
        this.$router.push('/signin');
      }
    },
    beforeMount() {
      this.$store.state.isTransparent = 'bg-transparent';
    },
  };
</script>
