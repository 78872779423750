<template>
  <nav aria-label="breadcrumb">
    <ol
      :class="`${this.$store.state.isRTL ? '' : ' me-sm-6'} ${
        this.$store.state.isNavFixed ? 'text-dark' : 'text-white'
      }`"
      class="px-0 pt-1 pb-0 mb-0 bg-transparent breadcrumb"
    >
      <li class="text-sm breadcrumb-item">
        <a
          v-if="this.$store.state.isRTL"
          :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
          class="opacity-5 ps-2"
          href="#"
        >لوحات القيادة</a
        >
        <a v-else :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'" class="opacity-8" href="#"
        >Pages</a
        >
      </li>
      <li
        :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'"
        aria-current="page"
        class="text-sm breadcrumb-item active"
      >
        {{ currentPage }}
      </li>
    </ol>
    <h6 :class="this.$store.state.isNavFixed ? 'text-dark' : 'text-white'" class="mb-0 font-weight-bolder">
      {{ currentPage }}
    </h6>
  </nav>
</template>

<script>
  export default {
    name: 'breadcrumbs-component',
    props: {
      currentPage: { required: true },
      textWhite: { type: String },
    },
  };
</script>
